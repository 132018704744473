<template>
  <b-overlay :show="isLoading" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-title>
        <div class="pt-2 px-2">
          Admin ดีเด่น
        </div>
      </b-card-title>
      <b-card-body>


        <b-form-select v-model="month" :options="options" title="label" @change="selectMonth"></b-form-select>
      </b-card-body>
      <b-table striped hover small responsive show-empty class="mt-2 position-relative items-center" :per-page="perPage"
        :items="items" :fields="fields">

        <template #empty="scope">
          <div class="p-2 text-center">
            <img src="/box.png" alt="empty" height="40px"> {{ scope.emptyFiltered ? 'No matching records found' :
    'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(avatar)="{ item }">
          <img :src="`/images/admin/${item.avatar}`" alt="" height="80" width="80" class="rounded-circle">
        </template>
      </b-table>
    </b-card>
  </b-overlay>
</template>

<script>
import { BModal, BFormInput, BFormGroup, BFormSelect, BPagination, BTable, BCard, BCardTitle, BCardBody, BOverlay, BIconController, BButton } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment-timezone';

export default {
  components: {
    BModal, BFormInput, BFormGroup, BFormSelect, BPagination, BTable, BCard, BCardTitle, BCardBody, BOverlay, BIconController, BButton,
    flatPickr
  },
  data: () => ({
    perPage: 10,
    pageOptions: [10, 15, 20],
    totalRows: 1,
    page: 1,
    exportPassword: '',
    options: [
      { text: "มกราคม", value: 1 },
      { text: "กุมภาพันธ์", value: 2 },
      { text: "มีนาคม", value: 3 },
      { text: "เมษายน", value: 4 },
      { text: "พฤษภาคม", value: 5 },
      { text: "มิถุนายน", value: 6 },
      { text: "กรกฎาคม", value: 7 },
      { text: "สิงหาคม", value: 8 },
      { text: "กันยายน", value: 9 },
      { text: "ตุลาคม", value: 10 },
      { text: "พฤศจิกายน", value: 11 },
      { text: "ธันวาคม", value: 12 }
    ],
    fields: [
      { key: 'avatar', label: 'Avatar' },
      { key: 'name', label: 'ชื่อ' },
      { key: 'admin_like', label: 'ยอดไลค์' },
    ],
    items: [],
    isLoading: false,
    month: moment().tz('Asia/Bangkok').month() + 1
  }),
  mounted() {
    this.getAdmins();
  },
  methods: {
    async getAdmins() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get('/outstanding-admins', {
          params: {
            month: this.month
          }
        })

        // this.totalRows = data.total;
        this.items = data;
        // console.log(data);

      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false
      }
    },
    async checkExportPassword() {
      if (this.exportPassword === 'gSEnn9hEH6Ts5wH') {
        this.exportData();
      } else {
        this.$swal({
          icon: 'error',
          title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
          text: 'รหัสผ่านไม่ถูกต้อง',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    },
    async selectMonth(val) {
      this.month = val;
      this.getAdmins();
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>